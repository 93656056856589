import Link from "next/link";
import { useRouter } from "next/router";
import CurrentQuotation from "../CurrentQuotation";
function Header({
  back,
  image,
  rightText,
  title,
  small,
  edit,
  create,
  createInvoice,
  customStyles,
  children,
}) {
  const router = useRouter();

  return (
    <>
      <div
        className={`relative w-full bg-[url('/assets/image/header-image-1.png')] bg-cover bg-no-repeat flex items-end py-2.5  ${
          small ? "h-[200px] lg:!h-[200px]" : "h-[200px] lg:!h-[480px]"
        } ${customStyles}`}
      >
        <div className="headerGradient"></div>
        <div className="olafGrid">
          <h1 className="relative text-white max-w-[100%] flex items-center">
            {back !== "window" && back && (
              <Link legacyBehavior href={back}>
                <a>
                  <img
                    src="/assets/icon/chevron-left.svg"
                    width="14"
                    height="14"
                    className="mr-2.5 lg:w-6"
                    alt=""
                  />
                </a>
              </Link>
            )}
            {back == "window" && (
              <div onClick={() => router.back()} className="cursor-pointer">
                <img
                  src="/assets/icon/chevron-left.svg"
                  width="14"
                  height="14"
                  className="mr-2.5 lg:w-6"
                  alt=""
                />
              </div>
            )}
            {title}
            <div className="ml-auto">
              <p className="text-[12px]">{rightText}</p>
            </div>
            <div className="ml-auto hidden lg:flex space-x-4">
              {edit && (
                <Link legacyBehavior href={edit}>
                  <a className="group cursor-pointer w-[40px] h-[40px] items-center justify-center rounded-tl-[10px] rounded-br-[10px] bg-white text-olaf-green-500 text-sm font-bold gridArea save flex hover:bg-olaf-green-500 hover:text-white transition duration-200 ease-in-out">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                      className="text-olaf-green-500 w-4 h-4 group-hover:text-white transition duration-200 ease-in-out"
                    >
                      <path
                        fill="currentColor"
                        d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                      />
                    </svg>
                  </a>
                </Link>
              )}
              {createInvoice && (
                <div className="ml-auto lg:hidden" onClick={createInvoice}>
                  <img
                    src="/assets/icon/add-white.svg"
                    width="14"
                    height="14"
                    className="mr-2.5 cursor-pointer"
                    alt=""
                  />
                </div>
              )}
              {create && (
                <Link legacyBehavior href={create}>
                  <a className="ml-auto">
                    <img
                      src="/assets/icon/add-white.svg"
                      width="14"
                      height="14"
                      className="mr-2.5"
                      alt=""
                    />
                  </a>
                </Link>
              )}
              {children}
            </div>
          </h1>
        </div>
        <CurrentQuotation />
      </div>
      <div className="olafGrid flex flex-wrap gap-x-4 gap-y-4 pt-4 lg:hidden empty:pt-0">
        {edit && (
          <Link legacyBehavior href={edit}>
            <a className="group cursor-pointer w-[40px] h-[40px] items-center justify-center rounded-tl-[10px] rounded-br-[10px] bg-white text-olaf-green-500 text-sm font-bold gridArea save flex hover:bg-olaf-green-500 hover:text-white transition duration-200 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="text-olaf-green-500 w-4 h-4 group-hover:text-white transition duration-200 ease-in-out"
              >
                <path
                  fill="currentColor"
                  d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                />
              </svg>
            </a>
          </Link>
        )}
        {createInvoice && (
          <div className="ml-auto lg:hidden" onClick={createInvoice}>
            <img
              src="/assets/icon/add-white.svg"
              width="14"
              height="14"
              className="mr-2.5 cursor-pointer"
              alt=""
            />
          </div>
        )}
        {create && (
          <Link legacyBehavior href={create}>
            <a className="ml-auto">
              <img
                src="/assets/icon/add-white.svg"
                width="14"
                height="14"
                className="mr-2.5"
                alt=""
              />
            </a>
          </Link>
        )}
        {children}
      </div>
    </>
  );
}

export default Header;
