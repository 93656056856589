import Head from "next/head";
import Navbar from "../components/general/Navbar";
import Header from "../components/general/Header";
import HiddenMenu from "../components/general/HiddenMenu";
import Footer from "../components/general/Footer";
import { useState, useEffect } from "react";
import AuthServices from "../services/Auth.services";
import Section404 from "../components/general/Section404";

export default function Home({navigation, loggedIn, jwtToken }) {
  const [auth, setAuth] = useState(loggedIn);
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    if (!jwtToken) {
      return;
    }
    setUserInfo(AuthServices.getUserData(jwtToken));
  }, []);

  return (
    <div className="min-h-screen flex flex-col">
      <Head>
        <title>Olaf Nijenkamp | Home</title>
        <meta name="description" content="Generated by create next app" />
      </Head>

      <header>
        <Navbar items={navigation} />
        <HiddenMenu loggedIn={loggedIn} role={userInfo?.role} />
        <Header small={true} customStyles="pb-8 lg:pb-20" />
      </header>

      <main className="olafGrid z-10">
        <Section404 />
      </main>

      <Footer />
    </div>
  );
}
