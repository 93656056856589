import Link from "next/link";

export default function Section404() {
  return (
    <section className="flex flex-col items-center my-16">
      <img
        src="/assets/image/404.svg"
        alt="Walking stick"
        className="w-full max-w-[80%] md:max-w-[600px]"
      />
      <h2 className="text-center md:text-left mt-8">
        Helaas, deze pagina bestaat niet. Ga naar de{" "}
        <Link legacyBehavior href={"/"}>
          <a className="md:text-3xl underline">Homepage</a>
        </Link>
      </h2>
    </section>
  );
}
